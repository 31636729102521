<!--
 * @作者: kerwin
-->
<template>
  <div class="container">
    <el-carousel height="calc(100vh - 59px)">
      <el-carousel-item :key="1">
        <div
          :style="{ backgroundImage: `url(${require('@/assets/home1.jpg')})` }"
        ></div>
      </el-carousel-item>
      <el-carousel-item :key="2">
        <div
          :style="{ backgroundImage: `url(${require('@/assets/home2.jpg')})` }"
        ></div>
      </el-carousel-item>
      <el-carousel-item :key="3">
        <div
          :style="{ backgroundImage: `url(${require('@/assets/home3.jpg')})` }"
        ></div>
      </el-carousel-item>
    </el-carousel>
    <div class="center">互联网+时代</div>
  </div>


<footer>
    <div class="footer-content">

        <div class="footer-section">
            <h3>版权声明</h3>
            <p>&copy; 2024 [创厦通讯]. 保留所有权利。<br><a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">闽ICP备2024073168号-1</a></p>

        </div>
        <div class="footer-section">
             <h3>联系方式</h3>
             <p>邮箱：18250428888@139.com</p>
             <p>电话：18250428888</p>
        </div>
        <div class="footer-section">
            <h3>社交媒体</h3>
            <ul class="social-icons">
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
            </ul>
        </div>
        <div class="footer-section">
            <h3>网站地图</h3>
            <ul>
                <li><a href="#">首页</a></li>
                <li><a href="#">新闻</a></li>
                <li><a href="#">产品与服务</a></li>
                <li><a href="#">登录</a></li>
            </ul>
        </div>
    </div>
</footer>
</template>

<style scoped lang="scss">
.el-carousel div {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.container {
  position: relative;
}
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100px;
  line-height: 100px;
  font-size: 60px;
  color: white;
  text-align: center;
}
.beian-link {
  // 设置文本颜色为蓝色（可根据喜好调整颜色值）
  color: blue;
  // 去掉下划线，让链接看起来更简洁
  text-decoration: none;
  // 添加鼠标悬停时的效果，比如改变颜色为深蓝色
  &:hover {
    color: darkblue;
  }
  // 可以添加一些字体样式，比如设置字体加粗（可根据喜好调整）
  font-weight: bold;
}
footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer-section {
    width: 200px;
    margin: 0 10px;
}

.footer-section h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 5px;
}

.footer-section ul li a {
    color: #fff;
    text-decoration: none;
}

.footer-section ul li a:hover {
    text-decoration: underline;
}

.social-icons {
    display: flex;
}

.social-icons li {
    margin-right: 10px;
}

.social-icons a {
    color: #fff;
    font-size: 20px;
}
.footer-section p:last-child {
    font-size: 14px;
    color: #ccc;
}
.footer-section a {
    color: inherit;
    text-decoration: none;
}
</style>
