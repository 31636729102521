<!--
 * @作者: kerwin
-->
<template>
  <div>
    <el-carousel
      height="calc(100vh - 59px)"
      direction="horizontal"
      :autoplay="false"
      v-if="looplist.length"
    >
      <el-carousel-item v-for="item in looplist" :key="item._id">
        <div
          class="item"
          :style="{
            backgroundImage: `url(http://localhost:3000${item.cover})`,
          }"
        >
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <h2>{{ item.title }}</h2>
              </div>
            </template>
            <div>{{ item.introduction }}</div>
            <div class="detail">{{ item.detail }}</div>

            <div class="more">
              更多信息，请访问：
              <br />
              http://www.baidu.com
            </div>
          </el-card>
        </div>
      </el-carousel-item>
    </el-carousel>
    <el-empty description="暂无产品" v-else />
  </div>
  <footer>
      <div class="footer-content">

          <div class="footer-section">
              <h3>版权声明</h3>
              <p>&copy; 2024 [创厦通讯]. 保留所有权利。<br><a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">闽ICP备2024073168号-1</a></p>

          </div>
          <div class="footer-section">
               <h3>联系方式</h3>
               <p>邮箱：18250428888@139.com</p>
               <p>电话：18250428888</p>
          </div>
          <div class="footer-section">
              <h3>社交媒体</h3>
              <ul class="social-icons">
                  <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                  <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                  <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              </ul>
          </div>
          <div class="footer-section">
              <h3>网站地图</h3>
              <ul>
                  <li><a href="#">首页</a></li>
                  <li><a href="#">新闻</a></li>
                  <li><a href="#">产品与服务</a></li>
                  <li><a href="#">登录</a></li>
              </ul>
          </div>
      </div>
  </footer>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
const looplist = ref([]);

onMounted(async () => {
 // const res = await axios.get("/webapi/product/list");
 // console.log(res);
  looplist.value = [];
});
</script>

<style scoped lang="scss">
.item {
  width: 100%;
  height: 100%;
  background-image: cover;
}

.box-card {
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  .detail,
  .more {
    margin-top: 20px;
  }
}
.beian-link {
  // 设置文本颜色为蓝色（可根据喜好调整颜色值）
  color: blue;
  // 去掉下划线，让链接看起来更简洁
  text-decoration: none;
  // 添加鼠标悬停时的效果，比如改变颜色为深蓝色
  &:hover {
    color: darkblue;
  }
  // 可以添加一些字体样式，比如设置字体加粗（可根据喜好调整）
  font-weight: bold;
}
footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
      position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #333;
        color: #fff;
        padding: 20px 0;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer-section {
    width: 200px;
    margin: 0 10px;
}

.footer-section h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 5px;
}

.footer-section ul li a {
    color: #fff;
    text-decoration: none;
}

.footer-section ul li a:hover {
    text-decoration: underline;
}

.social-icons {
    display: flex;
}

.social-icons li {
    margin-right: 10px;
}

.social-icons a {
    color: #fff;
    font-size: 20px;
}
.footer-section p:last-child {
    font-size: 14px;
    color: #ccc;
}
.footer-section a {
    color: inherit;
    text-decoration: none;
}

</style>
