<template>
    <el-row>
        <el-col
            :span="17"
            :offset="1"
        >
            <div>
                <h2> {{currentNews.title}}</h2>

                <div class="time">
                    {{whichTime(currentNews.editTime)}}
                </div>

                <el-divider>
                    <el-icon>
                        <star-filled />
                    </el-icon>
                </el-divider>

                <div v-html="currentNews.content"></div>
            </div>
        </el-col>
        <el-col
            :span="4"
            :offset="1"
            :pull="1"
        >
            <el-card class="box-card">
                <template #header>
                    <div class="card-header">
                        <span style="font-size:16px;font-weight:bold">最近新闻</span>

                    </div>
                </template>
                <div
                    v-for="item in topNews"
                    :key="item._id"
                    class="text item"
                    style="padding:14px"
                    @click="handleChange(item._id)"
                >
                    <span>{{item.title}}</span>
                    <div class="bottom">
                        <time class="time">{{ whichTime(item.editTime) }}</time>

                    </div>
                </div>
            </el-card>
        </el-col>
    </el-row>

    <footer>
        <div class="footer-content">

            <div class="footer-section">
                <h3>版权声明</h3>
                <p>&copy; 2024 [创厦通讯]. 保留所有权利。<br><a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">闽ICP备2024073168号-1</a></p>

            </div>
            <div class="footer-section">
                 <h3>联系方式</h3>
                 <p>邮箱：18250428888@139.com</p>
                 <p>电话：18250428888</p>
            </div>
            <div class="footer-section">
                <h3>社交媒体</h3>
                <ul class="social-icons">
                    <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
            <div class="footer-section">
                <h3>网站地图</h3>
                <ul>
                    <li><a href="#">首页</a></li>
                    <li><a href="#">新闻</a></li>
                    <li><a href="#">产品与服务</a></li>
                    <li><a href="#">登录</a></li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script setup>
import { onMounted, ref,watchEffect,onBeforeUnmount } from "vue";
import axios from "axios";
import { useRoute,useRouter } from "vue-router";
import moment from "moment";
import { StarFilled } from "@element-plus/icons-vue";

moment.locale("zh-cn");

const route = useRoute();
const router = useRouter();
const currentNews = ref({});
const topNews = ref([]);
const stop  = watchEffect(async () => {
//   console.log(111,route.params.id)
  if(!route.params.id) return 
  const res1 = await axios.get(`/webapi/news/list/${route.params.id}`);
  const res2 = await axios.get(`/webapi/news/toplist?limit=4`);
  //   console.log(res2.data.data)
  currentNews.value = res1.data.data[0];
  topNews.value = res2.data.data;
});

onBeforeUnmount(()=>{
    console.log(222,"onBeforeUnmount")
    stop()
})

const whichTime = time => {
  return moment(time).format("lll");
};

const handleChange = (id)=>{
    // console.log(id)
    router.push(`/news/${id}`)
}
</script>

<style scoped lang="scss">
.el-row {
  margin-top: 30px;
}

.time {
  font-size: 13px;
  color: gray;
}
.beian-link {
  // 设置文本颜色为蓝色（可根据喜好调整颜色值）
  color: blue;
  // 去掉下划线，让链接看起来更简洁
  text-decoration: none;
  // 添加鼠标悬停时的效果，比如改变颜色为深蓝色
  &:hover {
    color: darkblue;
  }
  // 可以添加一些字体样式，比如设置字体加粗（可根据喜好调整）
  font-weight: bold;
}
footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
     position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: #333;
            color: #fff;
            padding: 20px 0;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer-section {
    width: 200px;
    margin: 0 10px;
}

.footer-section h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 5px;
}

.footer-section ul li a {
    color: #fff;
    text-decoration: none;
}

.footer-section ul li a:hover {
    text-decoration: underline;
}

.social-icons {
    display: flex;
}

.social-icons li {
    margin-right: 10px;
}

.social-icons a {
    color: #fff;
    font-size: 20px;
}
.footer-section p:last-child {
    font-size: 14px;
    color: #ccc;
}
.footer-section a {
    color: inherit;
    text-decoration: none;
}
</style>
